import { template as template_9cc2e8047c444dd595b87c16f1d6bcbf } from "@ember/template-compiler";
const WelcomeHeader = template_9cc2e8047c444dd595b87c16f1d6bcbf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
