import { template as template_4751413a97ef4f1abaa9458962e1a979 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4751413a97ef4f1abaa9458962e1a979(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
