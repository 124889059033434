import { template as template_5cf7cf9262ba4e0683d6e81cc2b6c238 } from "@ember/template-compiler";
const FKText = template_5cf7cf9262ba4e0683d6e81cc2b6c238(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
