import { template as template_59e9ab9893ce49b3bcaeddf263ede990 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_59e9ab9893ce49b3bcaeddf263ede990(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
