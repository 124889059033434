import { template as template_6d06b8fb944d4951b244f9f7cbf66b47 } from "@ember/template-compiler";
const FKLabel = template_6d06b8fb944d4951b244f9f7cbf66b47(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
